import Sidebar from '@components/Sidebar';
import { createContext, useContext, useMemo, useState } from 'react';

const defaultState = { component: null, props: {} };

export const SidebarContext = createContext({
    component: null,
    actions: {
        open() {},
        close() {},
    },
});

export const useSidebar = () => useContext(SidebarContext);

const SidebarManager = () => {
    const { isOpened, component: Component, props, actions } = useSidebar();

    return (
        <Sidebar {...props} isOpened={isOpened} onClose={props.onClose ? props.onClose : actions.close} isClosable={props.isClosable}>
            {Component && <Component {...props} />}
        </Sidebar>
    );
};

export const SidebarProvider = ({ children }) => {
    const [openState, setOpenState] = useState(false);
    const [state, setState] = useState(defaultState);

    const actions = useMemo(
        () => ({
            open({ component = null, isClosable = true, ...props }) {
                setState({
                    component,
                    isClosable,
                    props: {
                        ...props,
                        isClosable,
                    },
                });
                setOpenState(true);
            },
            close() {
                setOpenState(false);
            },
        }),
        [],
    );

    return (
        <SidebarContext.Provider
            value={{
                isOpened: openState,
                component: state.component,
                isClosable: state.isClosable,
                props: state.props,
                actions,
            }}
        >
            <SidebarManager />
            {children}
        </SidebarContext.Provider>
    );
};
