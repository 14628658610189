import Modal from '@components/Modal';
import { createContext, useContext, useMemo, useState } from 'react';

const defaultState = { component: null, props: {} };

export const ModalContext = createContext({
    component: null,
    actions: {
        open() {},
        close() {},
    },
});

export const useModal = () => useContext(ModalContext);

const ModalManager = () => {
    const { isOpened, component: Component, props, actions } = useModal();

    return (
        <>
            <Modal {...props} isOpened={isOpened} onClose={props.onClose ? props.onClose : actions.close} isClosable={props.isClosable}>
                {Component && <Component {...props} />}
            </Modal>
        </>
    );
};

export const ModalProvider = ({ children }) => {
    const [openState, setOpenState] = useState(false);
    const [state, setState] = useState(defaultState);

    const actions = useMemo(
        () => ({
            open({ component = null, isClosable = true, ...props }) {
                setState({
                    component,
                    isClosable,
                    props: {
                        ...props,
                        isClosable,
                    },
                });
                setOpenState(true);
            },
            close() {
                setOpenState(false);
            },
        }),
        [],
    );

    return (
        <ModalContext.Provider
            value={{
                isOpened: openState,
                component: state.component,
                isClosable: state.isClosable,
                props: state.props,
                actions,
            }}
        >
            <ModalManager />
            {children}
        </ModalContext.Provider>
    );
};
