import Button from '@components/Button';
import FlexBox from '@components/FlexBox';
import Overlay from '@components/Overlay';
import Typography from '@components/Typography';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { useEffect } from 'react';

import * as style from './style.module.css';

const Sidebar = ({ children, title = '', subtitle = '', isOpened = false, onClose = () => {}, isClosable = true }) => {
    useEffect(() => {
        document.body.style.overflow = isOpened ? 'hidden' : 'unset';
        return () => (document.body.style.overflow = 'unset');
    }, [isOpened]);

    return (
        <>
            <Overlay isOpen={isOpened} zIndex={999999996} onClose={() => isClosable && onClose()} />
            <div className={`${style.sidebar} ${isOpened ? style.isOpen : ''}`}>
                {isClosable && (
                    <div className={style.titleContainer}>
                        <FlexBox flexDirection="column">
                            <Typography color="neutral-primary" fontWeight="semi-bold" fontSize="xl">
                                {title}
                            </Typography>
                            <Typography color="neutral-primary" fontWeight="regular" fontSize="l">
                                {subtitle}
                            </Typography>
                        </FlexBox>
                        <Button action={onClose} icon={faXmarkLarge} ghost />
                    </div>
                )}
                {children}
            </div>
        </>
    );
};

export default Sidebar;
