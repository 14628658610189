import Button from '@components/Button';
import Error from '@components/Error';
import { faBug } from '@fortawesome/pro-regular-svg-icons';
import { getSentry } from '@utils/sentry';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';

import * as style from './style.module.css';

class SentryErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null, eventId: null, isSentryEnabled: false };
        this.lock = false;
    }

    onReport() {
        const { eventId } = this.state;
        getSentry().then((Sentry) => Sentry?.showReportDialog({ eventId }));
    }

    componentDidCatch(error, errorInfo) {
        if (!this.lock) {
            this.lock = true;

            getSentry().then((Sentry) => {
                this.setState({ isSentryEnabled: !!Sentry });
                Sentry.withScope((scope) => {
                    scope.setExtras(errorInfo);
                    const exception = error.originalError || error.error || error;
                    if (exception) {
                        const eventId = Sentry.captureException(exception);
                        this.setState({ eventId });
                    }
                });
            });
        }
        this.setState({ error });
    }

    render() {
        const { error, isSentryEnabled } = this.state;
        const { children, t } = this.props;

        if (error) {
            return (
                <div className={style.container}>
                    <Error icon={faBug} message={t('A significant error has occurred.')} code={error.toString()} />
                    <div className={style.buttons}>
                        <Button
                            label={t('Reload the app')}
                            theme="primary"
                            action={() => {
                                document.location.href = '/';
                            }}
                        />
                        {isSentryEnabled && (
                            <Button
                                label={t('Report as bug')}
                                theme="primary"
                                action={() => {
                                    this.onReport();
                                }}
                            />
                        )}
                    </div>
                </div>
            );
        }
        return children;
    }
}

SentryErrorBoundary.propTypes = {
    children: PropTypes.any.isRequired,
};

export default withTranslation()(SentryErrorBoundary);
