import { faLoader } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import * as style from './style.module.css';

const Loader = ({ className = '' }) => {
    return (
        <div className={`${style.loading} ${className}`}>
            <FontAwesomeIcon icon={faLoader} spin />
        </div>
    );
};

Loader.propTypes = {
    className: PropTypes.string,
};

export default Loader;
