import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const BUILD = 'local';
const DEFAULT_LANGUAGE = window?.etesting?.language || 'en';

export const init = (i18nPath = '/v2/i18n/{{lng}}.json') =>
    i18n
        .use(backend)
        .use(detector)
        .use(initReactI18next)
        .init({
            compatibilityJSON: 'v4',
            lng: DEFAULT_LANGUAGE,
            fallbackLng: ['en'],
            keySeparator: false,
            nsSeparator: false,
            interpolation: {
                escapeValue: false,
            },
            backend: {
                loadPath: `${i18nPath}?${BUILD}`,
            },
            react: {
                useSuspense: false,
            },
            detection: {
                order: ['localStorage', 'navigator'],
                lookupLocalStorage: 'lng',
                lookupFromPathIndex: 0,
                lookupFromSubdomainIndex: 0,
                caches: ['localStorage'],
                checkWhitelist: true,
                checkForSimilarInWhitelist: false,
            },
            supportedLngs: ['en', 'fr', 'de', 'it', 'es', 'pl', 'nl', 'pt', 'ar'],
        });

export default i18n;

init();

export const getTranslatedString = (translatedValue = [], preferredLanguage = null) => {
    if (!Array.isArray(translatedValue)) {
        return null;
    }
    const preferred = (preferredLanguage ? translatedValue.find((e) => e.language === preferredLanguage)?.value : null) || null;
    const current = translatedValue.find((e) => e.language === DEFAULT_LANGUAGE)?.value || null;
    const fallbackFr = translatedValue.find((e) => e.language === 'en')?.value || null;
    const fallbackEn = translatedValue.find((e) => e.language === 'fr')?.value || null;
    const fallback = translatedValue?.[0]?.value || null;
    return preferred || current || fallbackFr || fallbackEn || fallback || null;
};
