import Action from '@components/Action';
import Loader from '@components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import * as style from './style.module.css';

function Button({
    title = '',
    label = '',
    disabled = false,
    theme = 'primary',
    icon = null,
    ghost = false,
    outline = false,
    isLoading = false,
    action = null,
    noPadding = false,
    rounded = false,
    ...props
}) {
    return (
        <Action
            {...props}
            action={!disabled && !isLoading ? action : null}
            title={title}
            className={` ${style.button} ${style[theme]} ${isLoading ? style.isLoading : ''} ${disabled ? style.disabled : ''} ${ghost ? style.ghost : ''} ${outline ? style.outline : ''} ${noPadding ? style.noPadding : ''} ${rounded ? style.rounded : ''}`}
        >
            <div className={style.labelContainer}>
                {icon && <FontAwesomeIcon className={label ? style.icon : ''} icon={icon} />}
                {label}
            </div>
            <Loader className={style.loading} />
        </Action>
    );
}

Button.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    theme: PropTypes.oneOf(['primary', 'light', 'inverted']),
    icon: PropTypes.object,
    ghost: PropTypes.bool,
    outline: PropTypes.bool,
    isLoading: PropTypes.bool,
    noPadding: PropTypes.bool,
    rounded: PropTypes.bool,
    action: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

export default Button;
