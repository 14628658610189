import propTypes from 'prop-types';
import React from 'react';

import * as style from './style.module.css';

const headings = {
    1: ({ ...props }) => <h1 {...props} />,
    2: ({ ...props }) => <h2 {...props} />,
    3: ({ ...props }) => <h3 {...props} />,
    4: ({ ...props }) => <h4 {...props} />,
    5: ({ ...props }) => <h5 {...props} />,
    6: ({ ...props }) => <h6 {...props} />,
    fallback: ({ ...props }) => <p {...props} />,
};

const Typography = ({
    color,
    fontSize = 'm',
    fontWeight = 'regular',
    seo = 0,
    center = false,
    div = false,
    className = '',
    children = '',
    ...props
}) => {
    const Typo = div ? ({ ...props }) => <div {...props} /> : headings[parseInt(seo, 10)] || headings.fallback;

    return (
        <Typo
            className={`${center ? style.center : ''} ${style.font} ${className} ${style.pretty}`}
            style={{
                ...(color && { '--color': `var(--text-color-${color})` }),
                ...(fontWeight && { '--font-weight': `var(--font-weight-${fontWeight})` }),
                ...(fontSize && { '--font-size': `var(--font-${fontSize})` }),
            }}
            {...props}
        >
            {children}
        </Typo>
    );
};

Typography.propTypes = {
    color: propTypes.oneOf([
        '',
        'black',
        'white',
        'neutral-primary',
        'neutral-secondary',
        'neutral-tertiary',
        'neutral-inverted',
        'branded-primary',
        'branded-secondary',
        'success-primary',
        'warning-primary',
        'error-primary',
        'disabled',
    ]),
    fontSize: propTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl']),
    fontWeight: propTypes.oneOf([
        'thin',
        'extra-light',
        'light',
        'regular',
        'medium',
        'semi-bold',
        'bold',
        'extra-bold',
        'black',
        'extra-black',
    ]),
    seo: propTypes.number,
    div: propTypes.bool,
    center: propTypes.bool,
    className: propTypes.string,
    children: propTypes.node,
};

export default React.memo(Typography);
