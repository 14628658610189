import '@app/i18n';

import SentryErrorBoundary from '@components/SentryErrorBoundary';
import { ModalProvider } from '@contexts/ModalContext';
import { SidebarProvider } from '@contexts/SidebarContext';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { getPostHog } from './utils/posthog';
import { getSentry } from './utils/sentry';

// Initialize Sentry
getSentry();

// Initialize PostHog
getPostHog();

const Components = {
    'evaluation-view': () => import('@views/Evaluation'),
    'booking-view': () => import('@views/Booking'),
};

const registerWebComponent = (name, component) => {
    class WebComponent extends HTMLElement {
        static get observedAttributes() {
            return [];
        }

        connectedCallback() {
            const container = document.createElement('div');
            this.appendChild(container);

            this.root = ReactDOM.createRoot(container);
            this.render();
        }

        disconnectedCallback() {
            this.root.unmount();
        }

        attributeChangedCallback(name, oldValue, newValue) {
            if (oldValue !== newValue) {
                this.render();
            }
        }

        async render() {
            const Component = (await component()).default;
            const props = {};
            Array.from(this.attributes).forEach((attr) => {
                try {
                    props[attr.name] = JSON.parse(attr.value);
                } catch (e) {
                    props[attr.name] = attr.value;
                }
            });
            this.root.render(
                <React.StrictMode>
                    <SentryErrorBoundary>
                        <Suspense fallback={null}>
                            <ModalProvider>
                                <SidebarProvider>
                                    <Component {...props} />
                                </SidebarProvider>
                            </ModalProvider>
                        </Suspense>
                        <ToastContainer />
                    </SentryErrorBoundary>
                </React.StrictMode>,
            );
        }
    }

    customElements.define(name, WebComponent);
};

Object.keys(Components).forEach((name) => {
    registerWebComponent(`tami-${name}`, Components[name]);
});
