import Typography from '@components/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as style from './style.module.css';

const Error = ({ icon, message = '', code = '', margin = true }) => (
    <div className={`${style.error} ${margin ? '' : style.noMargin}`}>
        <FontAwesomeIcon className={style.icon} icon={icon} />
        <Typography fontSize="xxl" color="branded-secondary" className={style.message}>
            {message}
        </Typography>
        {code && (
            <Typography color="branded-secondary" className={style.code}>
                {code}
            </Typography>
        )}
    </div>
);

export default Error;
