import * as style from './style.module.css';

const Action = ({ action = null, target = '_blank', className = '', ...props }) => {
    const dest = action;
    const actionType = dest && typeof dest;

    if (actionType === 'function') {
        return <span className={`${style.action} ${className}`} onClick={dest} {...props} />;
    }

    if (actionType === 'string' && dest[0] === '/') {
        return <a className={`${style.action} ${className}`} href={dest} {...props} />;
    }

    if (actionType === 'string' && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,25})+$/.test(dest)) {
        return <a className={`${style.action} ${className}`} href={`mailto:${dest}`} rel="noopener noreferrer" {...props} />;
    }

    if (actionType === 'string' && dest[0] === '#') {
        return <a className={`${style.action} ${className}`} href={dest} {...props} />;
    }

    if (actionType === 'string' && dest[0] !== '/') {
        return (
            <a
                className={`${style.action} ${style.link} ${className}`}
                href={dest}
                rel="noopener noreferrer"
                {...(target ? { target } : {})}
                {...props}
            />
        );
    }

    return <span className={`${style.action} ${style.noaction} ${className}`} {...props} />;
};

export default Action;
