let SentryInstance = null;

export const getSentry = async () => {
    if (SentryInstance) {
        return SentryInstance;
    }

    const env = window?.etesting?.env;
    const SENTRY_DSN = env?.SENTRY_DSN;
    const GIT_HASH = env?.GIT_HASH || 'UNKNOW';
    const ENVIRONMENT = env?.ENVIRONMENT || 'UNKNOW';

    if (SENTRY_DSN) {
        SentryInstance = await import('@sentry/react');

        SentryInstance.init({
            dsn: SENTRY_DSN,
            integrations: [SentryInstance.replayIntegration()],
            // Capture Replay for 10% of all sessions, can be changed to 100% while debugging,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            environment: ENVIRONMENT,
            release: GIT_HASH,
        });
        return SentryInstance;
    }

    return null;
};
