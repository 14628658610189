import Action from '@components/Action';
import Overlay from '@components/Overlay';
import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';

import * as style from './style.module.css';

function Modal({ title = null, children = null, isOpened = true, onClose = () => {}, mode = 'MODAL', isClosable = true, size = 'm' }) {
    useEffect(() => {
        document.body.style.overflow = isOpened ? 'hidden' : 'unset';
        return () => (document.body.style.overflow = 'unset');
    }, [isOpened]);

    return (
        <>
            <Overlay isOpen={isOpened} zIndex={999999998} onClose={() => isClosable && onClose()} />
            <div
                className={`${style.content} ${size === 'l' ? style.large : size === 's' ? style.small : ''} ${style?.[mode?.toLowerCase()] || ''} ${isOpened ? style.isOpened : ''}`}
            >
                {isClosable && (
                    <Action action={onClose} className={style.closeIcon}>
                        <FontAwesomeIcon icon={faXmarkLarge} />
                    </Action>
                )}
                {title && (
                    <>
                        <h2 className={style.header}>{title}</h2>
                        <hr className={style.hr} />
                    </>
                )}
                <div className={style.body}>{children}</div>
            </div>
        </>
    );
}

export default Modal;
