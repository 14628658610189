let PostHogInstance = null;

export const getPostHog = async () => {
    if (PostHogInstance) {
        return PostHogInstance;
    }

    const env = window?.etesting?.env;
    const POSTHOG_API_KEY = env?.POSTHOG_API_KEY;

    try {
        if (POSTHOG_API_KEY) {
            const posthog = await import('posthog-js');
            PostHogInstance = posthog.default;

            PostHogInstance.init(POSTHOG_API_KEY, {
                api_host: 'https://eu.i.posthog.com',
                person_profiles: 'always',
                autocapture: false,
                capture_pageview: false,
                capture_dead_clicks: false,
                disable_surveys: true,
                disable_session_recording: true,
            });

            return PostHogInstance;
        }
    } catch (err) {
        console.error(err);
    }
};
